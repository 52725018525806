import React from 'react'
import './NoticeBoard.css'
import logo from '../../assets/logo-small.png'
import plane from '../../assets/img/plane.png'
import review from '../../assets/img/smile.png'
import joinus from '../../assets/img/this-place.png'
import { Link } from 'react-router-dom'

export default function NoticeBoard() {
  return (
    <div className='noticeboard'>
        <div className='sop-box'>
            {/* <p>Find what you're looking for?</p> */}
            <img src={logo} alt='logo' className='sop' />
            <p>Verified by us, guaranteed by us.</p>
            <span>Look for our stamp of approval</span>
        </div>
        <div>
            <img src={review} alt='smile' />
            <h5>Review your person</h5>
            <p>Why don't you leave a review for your service provider.</p>
            <button className='btn-2'>Write a review</button>
        </div>
        <div>
            <img src={joinus} alt='joinus' />
            <h5>Sign up as a service provider</h5>
            <p>Join the Rolling Index</p>
            <button className='btn-2'>It's free</button>
        </div>
        <div className='sop-box contact-box'>
            <h2>Need help?</h2>
            <span>Our friendly helpdesk offers 24/7 live chat and email support.</span>
            <Link to="/contact" className='btn-2'>Contact us</Link>
            <img src={plane} alt='joinus' />
        </div>
    </div>
  )
}
