import React from 'react'
import './Foot.css'
import logo from '../../assets/logo-light.png'
import { Link } from 'react-router-dom'

export default function Foot() {
  return (
    <div className='foot'>
        <div>
            <Link to='/'>Home</Link>
            <Link to='/'>Promotions</Link>
            <Link to='/'>Settings</Link>
            <Link to='/'>Contact Us</Link>
            <Link to='/'>FAQ</Link>
            <Link to='/'>Terms of Use</Link>
            <Link to='/'>Privacy Notice</Link>
            <Link to='/'>Sitemap</Link>
            <Link to='/'>Complaints about a member</Link>
        </div>
        <img src={logo} alt='logo' />
        <p>© Rolodex all rights reserved</p>
    </div>
  )
}
