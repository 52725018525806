import React from 'react'
import './Featured.css'
import chevron from '../../assets/img/chevron.png'

import FeaturedSummary from './FeaturedSummary'

export default function Featured() {
  return (
    <div className='featured sidescroll'>
        <h1>Featured</h1>
        <div className='company-wrap'>
          <img src={chevron} alt='left' className='chevron chevron-l' />
          <FeaturedSummary />
          <img src={chevron} alt='right' className='chevron chevron-r' />
        </div>
    </div>
  )
}
