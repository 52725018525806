// import logoPlaceholder from '../../assets/img/placeholder.png'
import { useCollection } from '../../hooks/useCollection'

export default function FeaturedSummary() {
  const { isPending, error, documents } = useCollection('business')
  // const [ featured, setFeatured ] = useState(null)

  return (
    <>
      {isPending && <p className='loading'>Loading featured...</p>}
      {error && <div>{error}</div>}
      {documents && documents.map(b => (
        <div key={b.id} >
          <img src={b.logo} alt="business"/>
          <h5>{b.name}</h5>
          <p>{b.category}</p>
        </div>
      ))}
    </>
  )
}
