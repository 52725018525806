import './Menu.css'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGrip, faBullhorn, faInfoCircle, faPhone } from '@fortawesome/free-solid-svg-icons'

export default function Menu() {
  return (
    <div className='menu-container'>
        {/* <NavLink to='/'><FontAwesomeIcon icon={faHouse} />Home</NavLink> */}
        <NavLink to='/categories'><FontAwesomeIcon icon={faGrip} />Categories</NavLink>
        <NavLink to='/promo'><FontAwesomeIcon icon={faBullhorn} />Promotions</NavLink>
        <NavLink to='/about'><FontAwesomeIcon icon={faInfoCircle} />About us</NavLink>
        <NavLink to='/contact'><FontAwesomeIcon icon={faPhone} />Contact us</NavLink>
        {/* <NavLink to='/'><FontAwesomeIcon icon={faCog} />Settings</NavLink> */}
    </div>
  )
}
