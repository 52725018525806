import React from 'react'
import './Categories.css'
import ph from '../../assets/img/placeholder.png'
import { useCollection } from '../../hooks/useCollection'
import { Link } from 'react-router-dom'

export default function Categories() {
  const { isPending, error, documents } = useCollection('categories')

  // const imgFind = () => {
  //   return c.img
  // }

  return (
    <div className='page cat-main'>
      <h1>All Categories</h1>
      <div className='cat-grid'>
        {isPending && <p className='loading'>Loading categories...</p>}
        {error && <div>{error}</div>}
        {documents && documents.map(c => (
          <Link to={`/categories/${c.id}`} key={c.id} >
            {!c.img && (
              <>
                <img src={ph} alt="placeholder" />
              </>
            )}
            {c.img && (
              <>
                <img src={c.img} alt={c.name} />
              </>
            )}
            <h2>{c.name}</h2>
          </Link>
        ))}
      </div> 
      <Link className='btn-pill' to='/contact' >Suggest a Category</Link>
    </div>
  )
}
