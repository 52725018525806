import React from 'react'
import { Link } from 'react-router-dom'
import './Search.css'
import logo from '../../assets/img/placeholder.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot, faSearch, faXmark } from '@fortawesome/free-solid-svg-icons'

export default function Search() {
  return (
    <div className='page search-main'>
      <h1>Search</h1>
      {/* <div className='cat-grid'>
        {isPending && <p className='loading'>Loading categories...</p>}
        {error && <div>{error}</div>}
        {documents && documents.map(c => (
          <Link to={`/categories/${c.id}`} key={c.id} >
            <img src={logo} alt="placeholder"/>
            <h2>{c.name}</h2>
          </Link>
        ))}
      </div> 
      <button className='btn-2'>Suggest a Category</button> */}
      <div className='search-head'>
        <div className='breadcrumb'>
          <span className='pill'>tattoo<FontAwesomeIcon icon={faXmark} /></span>
          <span className='pill'>colour<FontAwesomeIcon icon={faXmark} /></span>
          <span className='pill'>sleeve<FontAwesomeIcon icon={faXmark} /></span>
          <span className='pill'>cartoon<FontAwesomeIcon icon={faXmark} /></span>
        </div>
        <div className='mm-search'>
            <input placeholder='Search..' />
            <FontAwesomeIcon icon={faSearch} />
        </div>
      </div>
      <div className='search-body'>
        <Link to='/user' className='s-box'>
          <img src={logo} alt="placeholder"/>
          <div>
            <h1>Company Name</h1>
            <h2>
              <FontAwesomeIcon icon={faLocationDot} />
              location
              <span>x km away</span>
            </h2>
            <h3>9.28<span>(x reviews)</span></h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim</p>
          </div>
        </Link>
        <div className='s-box'>
          <img src={logo} alt="placeholder"/>
          <div>
            <h1>Company Name</h1>
            <h2>
              <FontAwesomeIcon icon={faLocationDot} />
              location
              <span>x km away</span>
            </h2>
            <h3>9.1<span>(x reviews)</span></h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim</p>
          </div>
        </div>
        <div className='s-box'>
          <img src={logo} alt="placeholder"/>
          <div>
            <h1>Company Name</h1>
            <h2>
              <FontAwesomeIcon icon={faLocationDot} />
              location
              <span>x km away</span>
            </h2>
            <h3>9.4<span>(x reviews)</span></h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim</p>
          </div>
        </div>
        <div className='s-box'>
          <img src={logo} alt="placeholder"/>
          <div>
            <h1>Company Name</h1>
            <h2>
              <FontAwesomeIcon icon={faLocationDot} />
              location
              <span>x km away</span>
            </h2>
            <h3>9.4<span>(x reviews)</span></h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim</p>
          </div>
        </div>
        <div className='s-box'>
          <img src={logo} alt="placeholder"/>
          <div>
            <h1>Company Name</h1>
            <h2>
              <FontAwesomeIcon icon={faLocationDot} />
              location
              <span>x km away</span>
            </h2>
            <h3>9.4<span>(x reviews)</span></h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim</p>
          </div>
        </div>
        <div className='s-box'>
          <img src={logo} alt="placeholder"/>
          <div>
            <h1>Company Name</h1>
            <h2>
              <FontAwesomeIcon icon={faLocationDot} />
              location
              <span>x km away</span>
            </h2>
            <h3>9.4<span>(x reviews)</span></h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim</p>
          </div>
        </div>
      </div>
      <button className='btn-pill'>Load more</button>
    </div>
  )
}
