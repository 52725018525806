import React from 'react'
import './Banner.css'
import banner from '../../assets/img/banner-2.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationCrosshairs } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

export default function Banner() {
  return (
    <div className='banner'>
        <img src={banner} alt='banner' />
        <div className='cta'>
          <h1>Find who you're looking for?</h1>
          <p>The only rolling index of business contact information. Let's roll.</p>
          <div>
            <div>
              <input placeholder='Who are you looking for?' />
            </div>
            <div>
              <span><input placeholder='Location' /><FontAwesomeIcon icon={faLocationCrosshairs} /></span>
              {/* <button className='btn-2'>Search</button> */}
              <Link to='/search' className='btn-2'>Search</Link>
            </div>
          </div>
        </div>
    </div>
  )
}
