import { Link, NavLink } from 'react-router-dom'
import './MobileMenu.css'
import logo from '../../assets/logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faEnvelope, faHeart, faBell, faArrowRightFromBracket, faHouse, faGrip, faBullhorn, faInfoCircle, faPhone, faCog } from '@fortawesome/free-solid-svg-icons'
import { useLogout } from '../../hooks/useLogout'
import { useAuthContext } from '../../hooks/useAuthContext'

export default function MobileMenu( {data} ) {
  const { logout } = useLogout()
  const { user } = useAuthContext()

  const closeMenu = (e) => {   
    if(e.target === e.currentTarget) {
        return !data
     }
  }

  return (
    <div className={'mobilemenu mm-' + data} onClick={closeMenu}>
        <div>
            <div className='mm-head'>
                <img src={logo} alt='logo' />
            </div>
            <div className='mm-search'>
                <input placeholder='Search..' />
                <FontAwesomeIcon icon={faSearch} />
            </div>
            <div className='mm-tools'>
                <div>
                    <FontAwesomeIcon icon={faEnvelope} />
                    <p>Messages</p>
                </div>
                <div>
                    <FontAwesomeIcon icon={faBell} />
                    <p>Notifications</p>
                </div>
                <div>
                    <FontAwesomeIcon icon={faHeart} />
                    <p>Favourites</p>
                </div>
            </div>
            <div className='mm-menu'>
                <NavLink to='/'><FontAwesomeIcon icon={faHouse} />Home</NavLink>
                <NavLink to='/categories'><FontAwesomeIcon icon={faGrip} />Categories</NavLink>
                <NavLink to='/promo'><FontAwesomeIcon icon={faBullhorn} />Promotions</NavLink>
                <NavLink to='/about'><FontAwesomeIcon icon={faInfoCircle} />About us</NavLink>
                <NavLink to='/contact'><FontAwesomeIcon icon={faPhone} />Contact us</NavLink>
                <NavLink to='/'><FontAwesomeIcon icon={faCog} />Settings</NavLink>
            </div>
            <div className='mm-sign' >
                {user && (
                    <button onClick={logout}><FontAwesomeIcon icon={faArrowRightFromBracket} />Sign out</button>
                )}
                {!user && (
                    <>
                        <Link to='/signup' className='btn-2'>Register</Link>
                    </>
                )}
            </div>
        </div>
    </div>
  )
}
