import { initializeApp } from 'firebase/app'
import { getFirestore, serverTimestamp } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import { getStorage } from 'firebase/storage'
// import 'firebase/auth'
// import 'firebase/storage'

const firebaseConfig = {
    apiKey: "AIzaSyDmEnRw2hsDP-39sN2tH0laA4koJJSNPCw",
    authDomain: "rolodex-b5ed7.firebaseapp.com",
    projectId: "rolodex-b5ed7",
    storageBucket: "rolodex-b5ed7.appspot.com",
    messagingSenderId: "190705596679",
    appId: "1:190705596679:web:5f0bf5f7cb37007ff7ada2"
  };

// init firebase
initializeApp(firebaseConfig)

// init firestore
const db = getFirestore()
// init auth
const auth = getAuth()
// init storage
const hdd = getStorage()
// const projectStorage = firebase.storage()

// timestamp
const timestamp = serverTimestamp()

export { db, auth, hdd, timestamp }