import React, { useState } from 'react'
import logo from '../../assets/logo.png'
import './Head.css'
import MobileMenu from '../Menu/MobileMenu';
import { Link, useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../hooks/useAuthContext'
//ReactIcons
import * as FaIcons from 'react-icons/fa'
import Menu from '../Menu/Menu';
// import { useLogout } from '../../hooks/useLogout'


export default function Head() {
  const { user } = useAuthContext()
  // const { logout } = useLogout()

  let navigate = useNavigate();
  
  const [sidebar, setSidebar] = useState(false)
  
  const showSidebar = () => {
    setSidebar(!sidebar)
  }

  const goHome = () => {
    navigate('/home')
  }

  return (
    <>
      <div className='head'>
        <div className='mobile-menu'>
          <FaIcons.FaBars onClick={showSidebar}/>
          <MobileMenu data={sidebar} onChange={data => setSidebar(!data)} />
        </div>
        <div className='logo'>
          <img src={logo} alt='logo' onClick={goHome} />
        </div>
        <Menu />
        <div className='user'>
          {!user && (
            <>
              <Link className='btn-2' to='/signup'>Register</Link>
              <Link className='btn-simp' to='/login'>Login</Link>
            </>
          )}
          {user && (
            <>            
              <Link className='btn-pill' to='/'>Start a business</Link>
              <p className='display-name'>{user && user.displayName}</p>
            </>
          )}
          
        </div>
      </div>
    </>
  )
}
