import './ContactUs.css'
import Form from 'react-bootstrap/Form'
import plane from '../../assets/img/plane.png'

const options = [
    { value: 'suggestion', label: 'Suggest a category' },
    { value: 'finance', label: 'Finance' },
    { value: 'operations', label: 'Operations' },
    { value: 'hr', label: 'HR' },
    { value: 'it', label: 'IT' },
  ]

export default function ContactUs() {
  return (
    <div className='page contact-us'>
        <div className='page-deets'>
            <div>
                <h2>Please feel free to contact us.</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
            </div>
        </div>
        <img src={plane} alt='watermark' className='watermark on-the-right' />
        <div className='page-deets page-join-us contact-us-inputs'>
            <div>
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Your name,</Form.Label>
                <Form.Control 
                    required
                    type="text" 
                    placeholder="Enter your name please" 
                    // onChange={ (e) => setEmail(e.target.value) }
                    // value={email}
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>your email</Form.Label>
                <Form.Control 
                    required
                    type="email" 
                    placeholder="Enter your email please" 
                    // onChange={ (e) => setEmail(e.target.value) }
                    // value={email}
                />
            </Form.Group>
            <Form.Label>what about?</Form.Label>
            <Form.Select aria-label="Default select">
                <Form.Control
                    required
                    options={options}
                /> 
                {/* <option>Open this select menu</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option> */}
            </Form.Select>
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>and you message please.</Form.Label>
                <Form.Control 
                    required
                    type="text" 
                    placeholder="tell us what's up." 
                    // onChange={ (e) => setEmail(e.target.value) }
                    // value={email}
                />
            </Form.Group> 
            <button className='btn-pill'>Send it?</button>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>    
          </div>
        </div>
    </div>
  )
}
