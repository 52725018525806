import './User.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapLocationDot, faShield, faPhone, faEnvelope, faStar, faThumbsUp, faPencil, faReceipt, faCreditCard, faEraser, faGem, faPersonWalking } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faTwitch, faTwitter, faAirbnb, faInstagram } from '@fortawesome/free-brands-svg-icons'
import logo from '../../assets/img/placeholder.png'

export default function UserProfile() {
  return (
    <div className='user-main u-profile'>
      <div className='deets'>
        <div>
          <h1>Tiny Shop of Pain</h1>
          <p className='based'><FontAwesomeIcon icon={faMapLocationDot} />Based in <span>Woodstock, Cape Town</span></p>
          <span className='distance'>12 km away</span>
          <h5 className='reviews'>9.8 <span>(42 reviews)</span></h5>
          <h4 className='member'><FontAwesomeIcon icon={faShield} /><span>2+</span> years a member</h4>
        </div>
        <img src={logo} alt="placeholder"/>
      </div>
      <div className='u-brief'>
        <h5>Brief</h5>
        <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
        </p>
      </div>
      <div className='u-portfolio'>
        <h5>Portfolio</h5>
        {/* <div>
          <img src={logo} alt="placeholder"/>
          <img src={logo} alt="placeholder"/>
          <img src={logo} alt="placeholder"/>
          <img src={logo} alt="placeholder"/>
          <img src={logo} alt="placeholder"/>
          <img src={logo} alt="placeholder"/>
        </div> */}
        <div className='port-wrap'>
          <img src={logo} alt="placeholder"/>
          <div className='gallery'>
            <img src={logo} alt="placeholder"/>
            <img src={logo} alt="placeholder"/>
            <img src={logo} alt="placeholder"/>
            <img src={logo} alt="placeholder"/>
            <img src={logo} alt="placeholder"/>
            <img src={logo} alt="placeholder"/>
            <img src={logo} alt="placeholder"/>
            <img src={logo} alt="placeholder"/>
            <img src={logo} alt="placeholder"/>
            <img src={logo} alt="placeholder"/>
          </div>          
        </div>
      </div>
      <div className='u-contact-deets'>
        <h5>Contact Details</h5>
        <div>
          <FontAwesomeIcon icon={faPhone} />
          <p>+27 76 123 4567</p>
        </div>
        <div>
          <FontAwesomeIcon icon={faEnvelope} />
          <p>Someone@something.com</p>
        </div>
      </div>
      <div className='u-services'>
        <h5>Services</h5>
        <div className='service'>
          <span><FontAwesomeIcon icon={faReceipt} /></span>
          <p>Free Quotations</p>
        </div>
        <div className='service'>
          <span><FontAwesomeIcon icon={faCreditCard} /></span>
          <p>Cards Accepted</p>
        </div>
        <div className='service'>
          <span><FontAwesomeIcon icon={faPencil} /></span>
          <p>Bespoke Work</p>
        </div>
        <div className='service'>
          <span><FontAwesomeIcon icon={faEraser} /></span>
          <p>Tattoo Removal</p>
        </div>
        <div className='service'>
          <span><FontAwesomeIcon icon={faGem} /></span>
          <p>Body Piercing</p>
        </div>
        <div className='service'>
          <span><FontAwesomeIcon icon={faPersonWalking} /></span>
          <p>Walk-ins</p>
        </div>
      </div>
      <div className='u-socials'>
        <h5>Social Media</h5>
        <div className='socials'>
          <FontAwesomeIcon icon={faFacebook} />
          <FontAwesomeIcon icon={faTwitter} />
          <FontAwesomeIcon icon={faInstagram} />
          <FontAwesomeIcon icon={faTwitch} />
          <FontAwesomeIcon icon={faAirbnb} />
        </div>
      </div>
      <div className='u-ratings'>
        <h5>Ratings</h5>
        <div className='rating-block'>
          <h5>4.0</h5>
          <div className='stars'>
            <FontAwesomeIcon icon={faStar} className='bright' />
            <FontAwesomeIcon icon={faStar} className='bright' />
            <FontAwesomeIcon icon={faStar} className='bright' />
            <FontAwesomeIcon icon={faStar} className='bright' />
            <FontAwesomeIcon icon={faStar} />
          </div>
        </div>
        <div className='rate-wrap'>
          <div className='rate-circle'><span>4</span><p>Reliability</p></div>
          <div className='rate-circle'><span>5</span><p>Competency</p></div>
          <div className='rate-circle'><span>3</span><p>Workmanship</p></div>
          <div className='rate-circle'><span>4</span><p>Courtesy</p></div>
          <div className='rate-circle'><span>2</span><p>Tidiness</p></div>
          <div className='rate-circle'><span>2</span><p>Punctuality</p></div>
        </div>
      </div>
      <div className='u-reviews'>
        <h5>Reviews</h5>
        <div className='review'>
          <div className='stars'>
            <FontAwesomeIcon icon={faStar} className='bright' />
            <FontAwesomeIcon icon={faStar} className='bright' />
            <FontAwesomeIcon icon={faStar} className='bright' />
            <FontAwesomeIcon icon={faStar} className='bright' />
            <FontAwesomeIcon icon={faStar} />
          </div>
          <div>
            <h5>Customer name</h5>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
          </div>
          <div className='review-btns'>
            <button>
              <FontAwesomeIcon icon={faThumbsUp} />
              Helpful
              <span>(82)</span>
            </button>
            <button className='btn-clear'>Report review</button>
          </div>
        </div>
        <div className='review'>
          <div className='stars'>
            <FontAwesomeIcon icon={faStar} className='bright' />
            <FontAwesomeIcon icon={faStar} className='bright' />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
          </div>
          <div>
            <h5>Anonymous</h5>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
          </div>
          <div className='review-btns'>
            <button>
              <FontAwesomeIcon icon={faThumbsUp} />
              Helpful
              <span>(82)</span>
            </button>
            <button className='btn-clear'>Report review</button>
          </div>
        </div>
        <div className='review'>
          <div className='stars'>
            <FontAwesomeIcon icon={faStar} className='bright' />
            <FontAwesomeIcon icon={faStar} className='bright' />
            <FontAwesomeIcon icon={faStar} className='bright' />
            <FontAwesomeIcon icon={faStar} className='bright' />
            <FontAwesomeIcon icon={faStar} className='bright' />
          </div>
          <div>
            <h5>Job Title</h5>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
          </div>
          <div className='review-btns'>
            <button>
              <FontAwesomeIcon icon={faThumbsUp} />
              Helpful
              <span>(82)</span>
            </button>
            <button className='btn-clear'>Report review</button>
          </div>
        </div>
      </div>
    </div>
  )
}