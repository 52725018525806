import './App.css';
import { BrowserRouter, Routes, Route, Navigate  } from 'react-router-dom'
import Foot from './components/Foot/Foot';
import Head from './components/Head/Head';
//pages
import Home from './pages/home/Home';
import Search from './pages/search/Search'
import Create from './pages/create/Create';
import Build from './pages/build/Build';
import Promo from './pages/promo/Promo';
import Categories from './pages/categories/Categories';
import CategoryView from './pages/categories/CategoryView';
import Signup from './pages/signup/Signup';
import UserProfile from './pages/user/UserProfile';
import Login from './pages/login/Login';
import AboutUs from './pages/about/AboutUs';
import ContactUs from './pages/contact/ContactUs';
import { useAuthContext } from './hooks/useAuthContext'

function App() {
  const { user, authIsReady } = useAuthContext()

  return (
    <>
      {authIsReady && (
        <BrowserRouter>
          <div className="App">  
            <Head />
            <Routes>
              <Route exact path="/" element={ <Home /> } />
              <Route path="/search" element={ <Search /> } />
              <Route path="/signup" element={ user ? <Navigate to="/"/> : !user && <Signup /> } />
              <Route path="/login" element={ user ? <Navigate to="/"/> : !user && <Login /> } />
              <Route path="/create" element={ <Create /> } />
              <Route path="/build" element={ <Build /> } />
              <Route path="/promo" element={ user ? <Navigate to="/"/> : !user && <Promo /> } />
              <Route path="/categories" element={ <Categories /> } />
              <Route path="/categories/:id" element={ <CategoryView /> } />
              <Route path="/user" element={ <UserProfile /> } />
              <Route path="/about" element={ <AboutUs /> } />
              <Route path="/contact" element={ <ContactUs /> } />
              <Route path="*" element={ <Navigate to="/" />} />
            </Routes>
            <Foot />
          </div>
        </BrowserRouter> 
      )}
    </>
  );
}

export default App;
