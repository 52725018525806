import ph from '../../assets/img/placeholder.png'
import { useCollection } from '../../hooks/useCollection'

export default function CategoriesSummary() {
  const { isPending, error, documents } = useCollection('categories')

  return (
    <>
      {isPending && <p className='loading'>Loading categories...</p>}
      {error && <div>{error}</div>}
      {documents && documents.map(c => (
        <div key={c.id} >
            {!c.img && (
                <>
                    <img src={ph} alt="placeholder" />
                </>
            )}
            {c.img && (
                <>
                    <img src={c.img} alt={c.name} />
                </>
            )}
            <h5>{c.name}</h5>
        </div>
      ))}
    </>
  )
}
