import React from 'react'
import './Categories.css'
// import tat from '../../assets/img/categories/tattoo.png'
// import cleaner from '../../assets/img/categories/cleaner.png'
// import gardener from '../../assets/img/categories/gardener.png'
// import nail from '../../assets/img/categories/nail.png'
// import salon from '../../assets/img/categories/salon.png'
import chevron from '../../assets/img/chevron.png'
import CategoriesSummary from './CategoriesSummary'

export default function Categories() {
  return (
    <div className='categories sidescroll' >
        <h1>Categories</h1>
        <div className='cat-wrap'>
          <img src={chevron} alt='left' className='chevron chevron-l' />
          {/* <div>
            <img src={tat} alt='tattoo' />
            <h5>Tattoo Artists</h5>
          </div>
          <div>
            <img src={gardener} alt='gardener' />
            <h5>Gardeners</h5>
          </div>
          <div>
            <img src={cleaner} alt='cleaner' />
            <h5>Cleaners</h5>
          </div>
          <div>
            <img src={nail} alt='nail' />
            <h5>Beautician</h5>
          </div>
          <div>
            <img src={salon} alt='salon' />
            <h5>Stylist</h5>
          </div> */}
          <CategoriesSummary />
          <img src={chevron} alt='left' className='chevron chevron-r' />
        </div>
    </div>
  )
}
