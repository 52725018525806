import { useState } from 'react'
import { auth, db } from '../firebase/config'
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth'
import { useAuthContext } from "./useAuthContext";
import { collection, addDoc } from 'firebase/firestore'

export const useSignup = () => {
  const [error, setError] = useState(null)
  const [isPending, setIsPending] = useState(false)
  const { dispatch } = useAuthContext()

  const signup = (email, password, displayName, firstName, lastName, address, city, country, postal ) => {
    setError(null)
    setIsPending(true)

    createUserWithEmailAndPassword(auth, email, password)
      .then((res) => {        

        dispatch({ type: 'LOGIN', payload: res.user })

        updateProfile(auth.currentUser, { displayName })
        
        const ref = collection(db, 'users')
        // const createdAt = timestamp.fromDate(new Date())

        addDoc(ref, {
          displayName: displayName,
          uid: res.user.uid,
          firstName: firstName,
          lastName: lastName,
          address: address, 
          city: city, 
          country: country, 
          postal: postal,
          // createdAt: createdAt
        })
      })
      .catch((err) => {
        setError(err.message)
        setIsPending(false)
      })    

  }

  

  return { signup, error, isPending }
}