import './Login.css'
import { useState } from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { useLogin } from '../../hooks/useLogin'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserAstronaut } from '@fortawesome/free-solid-svg-icons'

export default function Login() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { error, login } = useLogin()
  
  const handleSubmit = (e) => {
    e.preventDefault()
    login(email, password)
  }
  return (
    <form className='page login' onSubmit={handleSubmit}>
        <h1>Login</h1>
        <div className='head-banner'>
        <FontAwesomeIcon icon={faUserAstronaut} />
        </div>
        <div>
        <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control 
                required
                type="email" 
                placeholder="Enter email" 
                onChange={ (e) => setEmail(e.target.value) }
                value={email}
            />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control 
                required
                type="password" 
                placeholder="Password" 
                onChange={ (e) => setPassword(e.target.value) }
                value={password}
            />
        </Form.Group>
        <Button variant="primary" type="submit" className='btn-2'>
            Login
        </Button>
        {error && <p className='error'>{error}</p>}
        </div>
    </form>
  )
}
