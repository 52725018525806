import { Link, useParams } from "react-router-dom"
import { useDocument } from '../../hooks/useDocument'
// fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
// styles
import './Categories.css'

export default function CategoryView() {
  const { id } = useParams()
  const { document, error } = useDocument('categories', id)

  if (error) {
    return <div className="error">{error}</div>
  }
  if (!document) {
    return <div className="loader">
      <p className="loading">Loading...</p>
    </div>
  }

  console.log(document)

  return (
    <div className="cat-view">
      <Link to={'/categories'} className='back btn-2'><FontAwesomeIcon icon={faArrowLeft} /></Link>
      <div>
        {/* {isPending && <p className='loading'>Loading category list...</p>} */}
        {/* <StoryDetails story={document} />
        <StoryTasks story={document} /> */}
        <h1>{document.name}</h1>
        <p>{document.details}</p>
      </div>
    </div>
    
  )
}