import React from 'react'
import Banner from '../../components/Banner/Banner'
import Categories from '../../components/Categories/Categories'
import Featured from '../../components/Featured/Featured'
import NoticeBoard from '../../components/NoticeBoard/NoticeBoard'
import './Home.css'

export default function Home() {
  return (
    <div className='home'>
        <Banner />
        <Featured />
        <Categories />
        <NoticeBoard />
    </div>
  )
}
