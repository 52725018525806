import './AboutUs.css'
import a1 from '../../assets/img/avatars/avatar-1.png'
import a2 from '../../assets/img/avatars/avatar-2.png'
import a3 from '../../assets/img/avatars/avatar-3.png'
import a4 from '../../assets/img/avatars/avatar-4.png'
import a5 from '../../assets/img/avatars/avatar-5.png'
import plane from '../../assets/img/plane.png'

export default function AboutUs() {
  return (
    <div className='page about-us'>
        <div className='page-deets'>
            <div>
                <h2>Meet the minds behind the virtual rolling index.</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
            </div>
        </div>
        <img src={plane} alt='watermark' className='watermark on-the-right' />
        <div className='staff-box'>
            <div>
                <h1>Michael Scott</h1>
                <h2>CEO</h2>
                <img src={a1} alt='avatar' />
                <p>Spend most of the day looking out the window.</p>
            </div>
            <div>
                <h1>Jim Halpert</h1>
                <h2>Head of Content</h2>
                <img src={a2} alt='avatar' />
                <p>Ensure that stupid people stay in the gene pool.</p>
            </div>
            <div>
                <h1>Dwight Shrute</h1>
                <h2>Designer</h2>
                <img src={a3} alt='avatar' />
                <p>Run away and call the police.</p>
            </div>
            <div>
                <h1>Pam Beesly</h1>
                <h2>Engineer</h2>
                <img src={a4} alt='avatar' />
                <p>Copy and paste things on the Internet.</p>
            </div>
            <div>
                <h1>Angela Martin</h1>
                <h2>QA</h2>
                <img src={a5} alt='avatar' />
                <p>Be a professional amateur, always wading into areas that I hardly know anything about.</p>
            </div>
        </div>
        <img src={plane} alt='watermark' className='watermark on-the-left' />
        <div className='page-deets page-join-us'>
            <div>
                <h2>Looking to make a mark on the world, try join our team.</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                <button className='btn-pill'>Join the team.</button>
            </div>
        </div>
    </div>
  )
}
