import { useState } from 'react'
import './Signup.css'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { useSignup } from '../../hooks/useSignup'


export default function Signup() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { error, signup } = useSignup()
  const [displayName, setDisplayName] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [address, setAddress] = useState('')
  const [city, setCity] = useState('')
  const [country, setCountry] = useState('')
  const [postal, setPostal] = useState('')
  // const [thumbnail, setThumbnail] = useState(null)
  // const [thumbnailError, setThumbnailError] = useState(null)

  const createDisplayName = (e) => {
    setLastName(e.target.value)
    setDisplayName( firstName.charAt(0) + lastName.charAt(0) )
  }
  
  const handleSubmit = (e) => {
    e.preventDefault()
    //console.log(displayName)
    signup(email, password, displayName, firstName, lastName, address, city, country, postal)
  }

  // const handleFileChange = (e) => {
  //   setThumbnail(null)
  //   let selected = e.target.files[0]
  //   console.log(selected)

  //   if (!selected) {
  //     setThumbnailError('Please select a file')
  //     return
  //   }
  //   if (!selected.type.includes('image')) {
  //     setThumbnailError('Selected file must be an image')
  //     return
  //   }
  //   if (selected.size > 10000000) {
  //     setThumbnailError('Image file size must be less than 1mb')
  //     return
  //   }
    
  //   setThumbnailError(null)
  //   setThumbnail(selected)
  //   console.log('thumbnail updated')
  // }

  return (
    <form className='page signup' onSubmit={handleSubmit}>
        <h1>Signup</h1>
        <div>
        <Form.Group className="mb-3" controlId="formBasicFirstName">
            <Form.Label>First Name</Form.Label>
            <Form.Control 
                // required
                type="text" 
                placeholder="Enter your first name" 
                onChange={ (e) => setFirstName(e.target.value) }
                value={firstName}
            />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicLastName">
            <Form.Label>Last Name</Form.Label>
            <Form.Control 
                // required
                type="text" 
                placeholder="Enter your last name" 
                onChange={ createDisplayName }
                value={lastName}
            />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control 
                required
                type="email" 
                placeholder="Enter email" 
                onChange={ (e) => setEmail(e.target.value) }
                value={email}
            />
            <Form.Text className="text-muted">
            We'll never share your email with anyone else.
            </Form.Text>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicAddress">
            <Form.Label>Street Address</Form.Label>
            <Form.Control 
                // required
                type="text" 
                placeholder="Enter your address" 
                onChange={ (e) => setAddress(e.target.value) }
                value={address}
            />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicCity">
            <Form.Label>City</Form.Label>
            <Form.Control 
                // required
                type="text" 
                placeholder="Enter your city" 
                onChange={ (e) => setCity(e.target.value) }
                value={city}
            />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicCountry">
            <Form.Label>Country</Form.Label>
            <Form.Control 
                // required
                type="text" 
                placeholder="Enter your country" 
                onChange={ (e) => setCountry(e.target.value) }
                value={country}
            />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPostal">
            <Form.Label>Postal Code</Form.Label>
            <Form.Control 
                // required
                type="text" 
                placeholder="Enter your postal code" 
                onChange={ (e) => setPostal(e.target.value) }
                value={postal}
            />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control 
                required
                type="password" 
                placeholder="Password" 
                onChange={ (e) => setPassword(e.target.value) }
                value={password}
            />
        </Form.Group>
        {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <Form.Check type="checkbox" label="Check me out" />
        </Form.Group> */}
        <Button variant="primary" type="submit" className='btn-2'>
            Sign up
        </Button>
        {error && <p className='error'>{error}</p>}
        </div>
    </form>
  )
}
